<template>
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        
        <a-form 
            :form="form" 
            style="padding:0 24px" 
            :label-col="{span:5}" 
            :wrapper-col="{span:12}"
            @submit="handleSubmit"
            >
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <h4>Edit</h4>
            </a-form-item>
            <a-form-item label="Name">
                <a-input
                v-decorator="['name', { rules: [{ required: true, message: 'Please input name!' }] }]"
                ></a-input>
            </a-form-item> 
            <a-form-item label="Type">
                <a-select v-decorator="['actiontype',{rules:[{required:true}]}]" @change="changetype">
                    <a-select-option value="sms">SMS</a-select-option>
                    <a-select-option value="mail">Email</a-select-option>
                    <a-select-option value="webhook">WebHook</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="To" v-show='isSms||isEmail'>
                <a-input v-decorator="['actionto']"></a-input>
            </a-form-item>
            <a-form-item label="Message" v-show='isSms||isEmail'>
                <a-textarea v-decorator="['actionmessage']"></a-textarea>
            </a-form-item>
            <a-form-item label="Url"  v-show='isWebHook'>
                <a-input v-decorator="['actionurl']"></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit" icon="save">Save</a-button>
                <a-space></a-space>
                <a-button type="secondary" icon="close" style="margin-left:10px" @click="backtogoal">Cancel</a-button>
            </a-form-item>
        </a-form>
    </a-card>
</template>
<script>
import {getaction,addorupdateaction} from '@/api/action'
export default ({
    props:{
        actionid:String
    },
    computed:{
        isSms: function(){
            return this.actiontype=='sms';
        },
        isEmail: function(){
            return this.actiontype=='mail';
        },
        isWebHook: function(){
            return this.actiontype=='webhook';
        }
    },
    data(){
        return {
            form:this.$form.createForm(this,{name: 'actionconfigure'}),
            actiontype : ''
        }
    },
    mounted(){
         if (this.actionid==0){
                this.form.setFieldsValue(
                    {
                        name:'',
                    }
                );
            }else{
        getaction(this.actionid).then(data=>{
            console.log(this.actionid)
            this.form.setFieldsValue({
                'name': data.name,
                'actiontype':data.actiontype,
                'actionto': data.actionto,
                'actionmessage':data.actionmessage,
                'actionurl': data.actionurl
            });
            this.changetype(data.actiontype,0);
        })}
    },
    methods:{
        // function: action-06
        backtogoal(){
            this.$router.go(-1);
        },
        changetype(v,o){
            this.actiontype = v;
        },
        // function: action-05
        handleSubmit(e){
            e.preventDefault();
            this.form.validateFields((err,values)=>{
                if (!err){
                    values.actionid=this.actionid;
                    console.log(values)
                    addorupdateaction(values).then(data=>{
                        this.$router.go(-1);
                    });
                }
            });
        }
    }
})
</script>
